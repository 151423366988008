.icon-container {
  display: flex;
  flex-direction: row;
  padding: 8px 0px;
}

.icon {
  width: 40px;
  height: 40px;
}

.icon:hover {
  cursor: pointer;
}
