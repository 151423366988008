.btn {
  background-color: #6e0592;
  transition: 0.3s;
}

.btn:hover {
  background-color: #321f49;
}

.btn-scndry {
  background-color: rgba(110, 5, 146, 0.5);
}

.btn-scndry:hover {
  background-color: rgba(110, 5, 146, 0.5);
}
