body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Rajdhani" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Odinson";
  src: local("Odinson"), url("./fonts/Odinson.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Heorot";
  src: local("Heorot"), url("./fonts/Heorot.ttf") format("truetype");
  font-weight: normal;
}
