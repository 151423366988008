.panel {
  margin: 36px;
  /* background-color: #35004e; */
  background-color: #3f005c;
  padding: 16px;
  border-radius: 30px;
  /* box-shadow: 1px 1px 32px rgba(153, 34, 187, 0.75); */
}

.panel-small {
  margin: 12px;
  padding: 12px;
}
