.landing-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 900px) {
  .landing-section {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
}
