.h2bcounter {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: #dedede solid 1px; */
  margin: 12px;
  background-color: #321f49;
  transition: 0.4s;
}

.h2bcounter:hover {
  background-color: rgba(110, 5, 146, 0.5);
  transition: 0.2s;
}

.h2bcounter-active {
  background-color: #6e0592;
  transition: 0.4s;
}

.h2bscrollbtn {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: "flex";
  justify-content: "center";
  align-items: "center";
}

.h2bscrollbtn-invert {
  transform: rotate(180deg);
}
