.rmcounter {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding: 6px;
  margin: 8px;
  background-color: #321f49;
  transition: 0.4s;
}

.rmcounter:hover {
  background-color: rgba(110, 5, 146, 0.5);
  transition: 0.2s;
}

.rmcounter-active {
  background-color: #6e0592;
  transition: 0.4s;
}
